import { gql } from 'apollo-angular';

import { CHANNEL_FRAGMENT } from './settings-definitions';
import { ASSET_FRAGMENT } from './product-definitions';
import { ERROR_RESULT_FRAGMENT } from './shared-definitions';

export const MERCHANT_ADDRESS_FRAGMENT = gql`
    fragment MerchantAddress on MerchantAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
        phoneNumber
    }
`;

export const MERCHANT_FRAGMENT = gql`
    fragment Merchant on Merchant {
        id
        createdAt
        updatedAt
        name
        slug
        description
        shippingAddress {
            ...MerchantAddress
        }
        featuredAsset {
            ...Asset
        }
        assets {
            ...Asset
        }
        channel {
          ...Channel
        }
        translations {
            id
            languageCode
            name
            description
        }
    }
    ${CHANNEL_FRAGMENT}
    ${ASSET_FRAGMENT}
    ${MERCHANT_ADDRESS_FRAGMENT}
`;

export const MERCHANT_BILL_FRAGMENT = gql`
    fragment MerchantBill on MerchantBill {
        id
        date
        numberOfOrders
        subTotal
        subTotalWithTax
        currencyCode
        paymentStates
    }
`;


export const GET_MERCHANTS = gql`
    query GetMerchants($options: MerchantListOptions) {
        merchants(options: $options) {
            items {
                ...Merchant
            }
            totalItems
        }
    }
    ${MERCHANT_FRAGMENT}
`;

export const GET_MERCHANT = gql`
    query GetMerchant($id: ID!) {
        merchant(id: $id) {
            ...Merchant
        }
    }
    ${MERCHANT_FRAGMENT}
`;

export const GET_AVAILABLE_MERCHANTS = gql`
    query GetAvailableMerchants($options: MerchantListOptions) {
        availableMerchants(options: $options) {
            items {
                ...Merchant
            }
            totalItems
        }
    }
    ${MERCHANT_FRAGMENT}
`;

export const CREATE_MERCHANT = gql`
    mutation CreateMerchant($input: CreateMerchantInput!) {
        createMerchant(input: $input) {
            ...Merchant
            ...ErrorResult
        }
    }
    ${MERCHANT_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const UPDATE_MERCHANT = gql`
    mutation UpdateMerchant($input: UpdateMerchantInput!) {
        updateMerchant(input: $input) {
            ...Merchant
            ...ErrorResult
        }
    }
    ${MERCHANT_FRAGMENT}
    ${ERROR_RESULT_FRAGMENT}
`;

export const GET_MERCHANT_BILLS = gql`
    query GetMerchantBills($options: MerchantBillListOptions) {
        merchantBills(options: $options) {
            items {
                ...MerchantBill
            }
            totalItems
        }
    }
    ${MERCHANT_BILL_FRAGMENT}
`;
